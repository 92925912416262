<template>
  <div class="debug framer">
    <router-link class="back" :to="{name:'Switcher'}"><img src="../assets/back.svg" /></router-link>
    <img class="logo" src="../assets/logo.svg">
    <h1>Technische Hilfsseite</h1>
    <h2>Zeichen</h2>
    <code>
      {{ token }}
    </code>
  </div>
</template>

<script>

export default {
  name: 'Debug',
  components: {
  },
  methods: {
  },
  computed: {
    token() {
      return localStorage['session token']
    }
  }
}

</script>

<style scoped lang="scss">


</style>

