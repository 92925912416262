<template>
  <div class="status framer">
    <Venue class="venue" />
    <h1>Vielen Dank!</h1>
    <img class="logo" src="../assets/logo.svg">
    <Monitor />
  </div>
</template>

<script>

import Monitor from '@/components/Monitor.vue'
import Venue from '@/components/Venue.vue'

export default {
  name: 'Status',
  components: {
    Monitor, Venue
  },
  methods: {
  },
  computed: {
  }
}

</script>

