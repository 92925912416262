
<template>
  <template v-if="order">
    <div class="clock">
      <div class="id">Nr. {{ order.id }}</div>
      <span :class="{elapsed:true,inverted:pulse}" v-if="order.completed">FERTIG</span>
      <span class="elapsed" v-else>{{ formattedElapsed }}</span>
    </div>
    <template v-if="order.completed">
      <p class="info">
        Du kannst dein Essen jetzt an der Theke abholen. Lass es dir schmecken!
      </p>
      <p v-if="hasEn">
        You can pick up your food at the counter now. Enjoy your meal!
      </p>
    </template>
    <template v-else>
      <template v-if="supportsInapp">
        <template v-if=" ! inappRequiresHomescreen || isHomescreen ">
          <p v-if="inAppPermission == 'prompt'" class="info">
            Mach’s dir einfach: Wir geben dir jetzt Bescheid, wenn dein Essen fertig ist.
            <br><br>
            <a class="button" href="" @click.stop.prevent="requestInapp()">Benachrichtigung erlauben</a>
          </p>
          <p v-if="inAppPermission == null && hasEn" class="info">
            Choose the easy way: We’ll tell you, as soon as your food is ready!
            <br><br>
            <a class="button" href="" @click.stop.prevent="requestInapp()">Allow notifications</a>
          </p>
          <p v-if="inAppPermission == 'denied'" class="info">
            Hier siehst du, wann dein Essen fertig ist. Bitte an der Theke abholen!
            <br>
            <br>
            <small>
              Hinweis: Benachrichtigungen sind in deinen Einstellungen blockiert. Wenn du informiert werden möchtest, sobald dein Essen fertig ist, bitte in den Einstellungen Benachrichtigungen erlauben.
            </small>
          </p>
          <p v-if="inAppPermission == 'denied' && hasEn" class="info">
            Here you can see when your food is ready. Please pick up at the counter!
            <br>
            <br>
            <small>
              Note: Notifications are blocked in your settings. If you want to be notified when your food is ready, please allow notifications in the settings.
            </small>
          </p>
          <p v-if="inAppPermission == 'granted'" class="info">
            Wir schicken dir eine Benachrichtigung, sobald dein Essen fertig ist &amp; abgeholt werden kann!
            <template v-if="hasEn">We will send you a notification when your food is ready to be picked up!</template>
          </p>
        </template>
        <template v-else>
        <p class="info">
          Hier siehst du, wann dein Essen fertig ist. Bitte an der Theke abholen!
          <br>
          <br>
          <small>
            Wähle <img class="ios-share" src="/ios-share.svg" alt="das Teilen-Symbol"> und dann "zum Home-Bildschirm hinzufügen". Bestelle da, um Benarichtigungen zu erhalten.
          </small>
        </p>
        <p class="info" v-if="hasEn">
          Here you can see when your food is ready. Please pick up at the counter!
          <br>
          <br>
          <small>
            Select <img class="ios-share" src="/ios-share.svg" alt="das Teilen-Symbol"> and "add to home screen" to receive push-notifications.
          </small>
        </p>
        </template>
      </template>
      <template v-else>
        <p class="info">
          Hier siehst du, wann dein Essen fertig ist. Bitte an der Theke abholen!
          <!--
          <br>
          <br>
          <small>
            Hinweis: Benachrichtigungen benötigen Android oder iOS ab 16.4
          </small>
          -->
        </p>
        <p class="info" v-if="hasEn">
          Here you can see when your food is ready. Please pick up at the counter!
          <br>
          <br>
          <small>
            Notifications require Android or Safari on iOS 16.4 or higher.
          </small>
        </p>
      </template>
      <!--
      <p v-if="notify=='none'" class="info">
        Sie werden wunschgemäss <i>nicht</i> benachrichtigt, wenn Ihr Essen fertig ist. Bitte behalten Sie die diese App im Blick. 
      </p>
      Normally, we don't do configurators, we find good one-size-fits-all solutions.
      But here, we just don't know who can receive email on their phone, or who likes
      Opera Mini that can't do notifications.
      <form class="notify">
        <label v-if="supportsInapp">
          <input type="radio" name="notify" :checked="notify=='inapp'" @change="change('notify', 'inapp') ; requestInapp()" @click="requestInapp()" />
          In-App (empfohlen)
        </label>
        <label>
          <input type="radio" name="notify" :checked="notify=='email'" @change="change('notify', 'email')" />
          E-Mail an ...
        </label>
        <label>
          <input type="radio" name="notify" :checked="notify=='sms'" @change="change('notify', 'sms')" />
          SMS an ...
        </label>
        <label>
          <input type="radio" name="notify" :checked="notify=='none'" @change="change('notify', 'none')" />
          Keine Benachrichtigung
        </label>
      </form>
      -->
    </template>
  </template>
  <p class="info" v-else-if="loaded">
    <Spinner />
    <br>
    Warte auf Bestellbestätigung... 
  </p>

  <p class="info" v-else>
    <Spinner />
    <br>
    <br>
    Lade Bestelldaten...
  </p>
  <router-link v-if=" ! softLock" :to="{name:'Home',params:{venue:this.$route.params.venue}}" class="back"><img src="@/assets/back.svg" /></router-link>
</template>

<script>

import ky from 'ky'

// GAWD this is hacky- almost like in the old days of frontend development.
// Perhaps remove this when we get push notifications and emails
import NoSleep from 'nosleep.js';
import Spinner from '@/components/Spinner.vue'

import store from '@/store'

import detect from '@/detect.js'

export default {
  name: 'Monitor',
  data: () => {
    return {
      orders: [],
      triggerTime: 0,
      serverTime: 0,
      tickTime: 0,
      loaded: false,
      pulse: false,
      inAppPermission: null,
      pstate: null
    }
  },
  props: {
    msg: String
  },
  methods: {
    change(key, value) {
      var json = {}
      json[key] = value
      ky.post(process.env.VUE_APP_API_URL + '/order/fulfillment', {
        json: json,
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        store.commit('fulfillment', data)
      })
    },
    trigger () {
      console.log("TRIGGER")
      // gets called by the polling mechanism below, see server endpoint for details
      ky.get(process.env.VUE_APP_API_URL + '/order/current/' + this.$route.params.venue, {
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        this.loaded = true
        this.orders = data.orders
        window.orders = data.orders

        // calibrate timer
        this.serverTime = new Date(data.serverTime)
        this.triggerTime = new Date()
      
      })
    },
    requestInapp() {
      var callback = p => {
        this.inAppPermission = p
      }
      Notification.requestPermission(callback).then(callback)
    }
  },
  computed: {
    order () {
      if (!this.orders) return null
      if (this.orders.length == 0) return null
      return this.orders[0]
    },
    orderTime () {
      if (this.order == null) return 0
      // this displays the oldest available order
      // relies on server-side order by received, ascending
      return new Date(this.orders[0].received)
    },
    elapsed () {
      if (this.serverTime == 0) return 0
      var clientElapsed = this.tickTime - this.triggerTime
      var serverElapsed = this.serverTime - this.orderTime
      return clientElapsed + serverElapsed
    },
    formattedElapsed () {
      if (this.elapsed == 0) return ''

      // naughty duration formatting hack
      var d = new Date(0);
      d.setMilliseconds(this.elapsed)
      if (this.elapsed < 3600000) {
        // Display minutes and seconds
        return d.toISOString().substr(14, 5)
      } else {
        // I hope we never have to display the hour. Cooks!!!
        return d.toISOString().substr(11, 8)
      }

    },
    softLock () {
      return this.loaded && this.order && ( ! this.order.completed)
    },
    supportsInapp () {
      return detect.supportsInapp()
    },
    isHomescreen() {
      return detect.isHomescreen()
    },
    iosVersion() {
      return detect.iosVersion()
    },
    inappRequiresHomescreen() {
      return detect.inappRequiresHomescreen()
    },
    notify() {
      return store.state.fulfillment.notify
    },
    hasEn() {
      return this.$route.params.venue.toLowerCase() == 'ber1'
    }
  },
  components: { Spinner },
  mounted () {
    window.addEventListener('beforeunload', function (e) {
      if (this.softLock) {
        e.preventDefault()
        return e.returnValue = ''
      }
      delete e['returnValue']
    })

    this.nosleep = new NoSleep()
    this.nosleep.enable()
    
    // state update poll timer
    var lock = false
    var counter = -1
    var clock = () => {
      this.tickTime = new Date() // trigger redraw of timer every second
      this.pulse = ! this.pulse
    }
    this.clockInterval = setInterval(clock, 1000)
    var poll = () => {
      if (lock) return;
      lock = true
      ky.get(process.env.VUE_APP_API_URL + '/poll', {
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(data => {
        lock = false
        if (counter != data) {
          counter = data
          this.trigger()
        }
      }).catch(() => {
        lock = false
      })
    }

    this.pollInterval = setInterval(poll, 3000)
    //setTimeout(poll, 10)

    function get_paypal_order_id() {
      var m = document.location.href.match(/token=([A-Z0-9]{10,200})/)
      if (m == null || m.length == 0) return null
      return m[1]
    }

    // paypal return trigger
    var paypal_order_id = get_paypal_order_id()
    if (paypal_order_id) {
      ky.post(process.env.VUE_APP_API_URL + '/paypal/capture', {
        json: paypal_order_id,
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      })
    }

    if (this.supportsInapp) {
      this.inAppPermission = null

      // first, query permissions
      navigator.permissions.query({ name: 'notifications' }).then(p => {

        // handle state change
        p.onchange = () => {
          console.log('notification state change: ' + p.state)
          if (p.state == 'granted' || (this.inappRequiresHomescreen && p.state == 'prompt')) {
            console.log('notification state granted')
            // permission newly granted, subscribe and store
            navigator.serviceWorker.ready.then(registration => {
              registration.pushManager.getSubscription().then(subscription => {
                console.log("subscribed", subscription)
                ky.get(process.env.VUE_APP_API_URL + '/push/key', {
                  headers: {
                    'session-token': localStorage.getItem('session token')
                  }
                }).json().then(applicationServerKey => {
                  registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: applicationServerKey
                  }).then(subscription =>  {
                    console.log("notification state subscribe", subscription)
                    ky.put(process.env.VUE_APP_API_URL + '/push/subscription', {
                      json: subscription,
                      headers: {
                        'session-token': localStorage.getItem('session token')
                      }
                    }).json().then(() => {
                      this.inAppPermission = 'granted'
                    })
                  })
                })
              })
            })
          } else {
            console.log('notification state clear')
            // permission withdrawn, clear it
            ky.delete(process.env.VUE_APP_API_URL + '/push/subscription', {
              headers: {
                'session-token': localStorage.getItem('session token')
              }
            }).json().then(() => {
              this.inAppPermission = p.state
            })
          }
        }

        // trigger state change on load to sync with frontend and backend
        p.onchange(p)
        this.inAppPermission = p.state
      })
    }
  },
  beforeUnmount () {
    clearInterval(this.pollInterval)
    clearInterval(this.clockInterval)
    this.nosleep.disable()
  }
}
</script>

<style lang="scss">

tr {
  th {
    text-align: left;
  }
  &.modifier {
    th {
      padding-left: 18px;
    }
  }
}

.clock {
  .elapsed {
    font-size: 48px;
    display: inline-block;
    margin: 12px 0 12px 0;
    padding: 22px 22px 12px 22px;
    &.inverted {
      color: white;
      background-color: black;
    }
  }
  .id {
    font-size: 24px;
  }
}

p.info {
  font-size: 125%;
  font-style: italic;
}

form.notify {
  label {
    cursor: pointer;
    user-select: none;
  }
}
</style>
